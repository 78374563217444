import { useState, useEffect } from "react";

const App = () => {
  const [value, setValue] = useState(null)
  const [message, setMessage] = useState(null)
  const [previousChats, setPreviousChats] = useState([])
  const [currentTitle, setCurrentTitle] = useState(null)
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768);

  const handleClickOldTitle = (uniqueTitle) => {
    setCurrentTitle(uniqueTitle)
    setValue("")
    setMessage(null)
  }

  const resetMainScreen = () => {
    setMessage(null)
    setValue("")
    setCurrentTitle(null)
  }

  const getMessages = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        message: value
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }

    try {
      const response = await fetch("https://sellygpt-3d86c6e8c352.herokuapp.com/completions", options);
      const data = await response.json();
      console.log(data)
      setMessage(data.choices[0].message);
      window.scrollTo(0, 0);

    } catch (error){
      console.error(error);
    }
  };

  useEffect(() => {
    //console.log(message, value, currentTitle)
    if (!currentTitle && message && value){
      setCurrentTitle(value)
    }

    if (currentTitle && message && value){
      setPreviousChats(previousChats => (
        [...previousChats,
        {
          title: currentTitle, 
          role: "You",
          content: value
        },
        {
          title: currentTitle, 
          role: "SellyGPT",
          content: message.content
        }])
      )
    }
  }, [message, currentTitle])

  const currentChat = previousChats.filter(previousChat => previousChat.title === currentTitle)
  const uniqueTitles = Array.from(new Set(previousChats?.map(previousChat => previousChat.title)))

  return (
    <div className="app">
      {isSidebarOpen &&(<section className="side-bar">
        <button onClick={resetMainScreen}>+ New Chat</button>
        <ul className="history">
          {uniqueTitles?.map((title, index) => (
            <li onClick={() => handleClickOldTitle(title)}>{title}</li>
          ))}
        </ul>
        <nav>
          <p>Made by Selly</p>
        </nav>
      </section>)}

      <section className="main">
      <div className="header-container">
        <div className="hamburger-container">
          <button className="hamburger-button" onClick={() => setSidebarOpen(!isSidebarOpen)}>☰</button>
        </div>
        <div className="header">
          <h1>SellyGPT</h1>
        </div>
      </div>
        <ul className="feed">
          {currentChat?.map((chatMessage, index) => 
            <li key={index}>
              <p className="role">{chatMessage.role}</p>
              <p>{chatMessage.content}</p>
            </li>
          )}
        </ul>
        <div className="bottom-section">
          <div className="input-container">
            <input value={value} onChange={(e) => setValue(e.target.value)} 
                                 onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                              getMessages();
                                            }
                                           }}/>
            <button id="submit" onClick={getMessages}>➢</button>
          </div>
          <p className="info">SellyGPT can make mistakes. Consider checking important information.</p>
        </div>
      </section>
    </div>
  );
}

export default App;
